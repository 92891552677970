<template>
  <div class="success">
    <!-- <i-nav-bar></i-nav-bar> -->
    <div class="middle">
      <div class="rorate_box">
        <img
          class="rorate rorate1"
          src="./../../assets/images/mySubmit/pic_rorate1.png"
          alt=""
        />
        <img
          class="rorate rorate2"
          src="./../../assets/images/mySubmit/pic_rorate2.png"
          alt=""
        />
        <img
          class="rorate rorate3"
          src="./../../assets/images/mySubmit/pic_rorate3.png"
          alt=""
        />
      </div>
      <div class="middle_txt">提交成功</div>
    </div>

    <div class="footer">
      <van-button class="btn_l" color="#4BFFF2" round @click="continueSubmit"
        >继续提交</van-button
      >
      <van-button class="btn_r mgl10" color="#111111" round @click="toHome"
        >完成</van-button
      >
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
export default {
  name: "Success",
  data() {
    return {};
  },
  components: {
    iNavBar,
  },
  mounted() {},
  methods: {
    // 继续提交
    continueSubmit() {
      LA.track('con_submit1_click');
      _hmt.push(["_trackEvent", "con_submit1_click", "click"]);
      this.$router.push({
        path: "/chooseIdo",
      });
    },
    // 返回首页
    toHome() {
      LA.track('finishbtn_click');
      _hmt.push(["_trackEvent", "finishbtn_click", "click"]);
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>
<style scoped lang="less">
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotationReverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.success {
  width: 100%;
  min-height: 100%;
  background: #5257cf;

  .middle {
    width: 140px;
    min-height: 135px;
    // background-color: yellow;
    margin: 50px auto 35px;
    .rorate_box {
      position: relative;
      width: 100%;
      min-height: 135px;
      //   background-color: red;
      .rorate {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .rorate1 {
        width: 140px;
        height: 135px;
        transform: rotate(360deg);
        animation: rotation 8s linear infinite;
      }
      .rorate2 {
        width: 107px;
        height: 107px;
        transform: rotate(360deg);
        animation: rotationReverse 2s linear infinite;
      }
      .rorate3 {
        width: 89px;
        height: 89px;
      }
    }
    .middle_txt {
      margin-top: 16px;
      text-align: center;
      font-size: 20px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
    }
  }

  .footer {
    width: 92%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;

    .btn_l {
      width: 72%;
      height: 40px;
      color: #111111;
      .van-button__text {
        color: #111111;
        line-height: 20px;
      }
    }
    .btn_r {
      width: 24%;
      height: 40px;
      .van-button__text {
        color: #4bfff2;
        line-height: 20px;
      }
    }
  }
}
</style>